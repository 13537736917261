//SnackBar
export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR";

//Bet
export const GET_ALL_BET_REQUEST = "GET_ALL_BET_REQUEST";
export const GET_ALL_BET_SUCCESS = "GET_ALL_BET_SUCCESS";
export const GET_ALL_BET_FAILURE = "GET_ALL_BET_FAILURE";

//Login
export const POST_LOGIN_REQUEST = "POST_LOGIN_REQUEST";
export const POST_LOGIN_SUCCESS = "POST_LOGIN_SUCCESS";
export const POST_LOGIN_FAILURE = "POST_LOGIN_FAILURE";

//Register
export const POST_REGISTER_REQUEST = "POST_REGISTER_REQUEST";
export const POST_REGISTER_SUCCESS = "POST_REGISTER_SUCCESS";
export const POST_REGISTER_FAILURE = "POST_REGISTER_FAILURE";

//User Bet
export const POST_USER_BET_REQUEST = "POST_USER_BET_REQUEST";
export const POST_USER_BET_SUCCESS = "POST_USER_BET_SUCCESS";
export const POST_USER_BET_FAILURE = "POST_USER_BET_FAILURE";

//User Info
export const GET_USER_INFO_REQUEST = "GET_USER_INFO_REQUEST";
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILURE = "GET_USER_INFO_FAILURE";

//Update User Password
export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

//Get User Betting Record
export const GET_BETTING_RECORD_REQUEST = "GET_BETTING_RECORD_REQUEST";
export const GET_BETTING_RECORD_SUCCESS = "GET_BETTING_RECORD_SUCCESS";
export const GET_BETTING_RECORD_FAILURE = "GET_BETTING_RECORD_FAILURE";

//Get Transaction Record
export const GET_TRANSACTION_RECORD_REQUEST = "GET_TRANSACTION_RECORD_REQUEST";
export const GET_TRANSACTION_RECORD_SUCCESS = "GET_TRANSACTION_RECORD_SUCCESS";
export const GET_TRANSACTION_RECORD_FAILURE = "GET_TRANSACTION_RECORD_FAILURE";

//Get Today Transaction
export const GET_TODAY_TRANSACTION_REQUEST = "GET_TODAY_TRANSACTION_REQUEST";
export const GET_TODAY_TRANSACTION_SUCCESS = "GET_TODAY_TRANSACTION_SUCCESS";
export const GET_TODAY_TRANSACTION_FAILURE = "GET_TODAY_TRANSACTION_FAILURE";

//Get Announcement
export const GET_ANNOUNCEMENT_REQUEST = "GET_ANNOUNCEMENT_REQUEST";
export const GET_ANNOUNCEMENT_SUCCESS = "GET_ANNOUNCEMENT_SUCCESS";
export const GET_ANNOUNCEMENT_FAILURE = "GET_ANNOUNCEMENT_FAILURE";

//Get Agent Record
export const GET_AGENT_REQUEST = "GET_AGENT_REQUEST";
export const GET_AGENT_SUCCESS = "GET_AGENT_SUCCESS";
export const GET_AGENT_FAILURE = "GET_AGENT_FAILURE";