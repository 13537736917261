import {
  GET_ANNOUNCEMENT_FAILURE,
  GET_ANNOUNCEMENT_REQUEST,
  GET_ANNOUNCEMENT_SUCCESS,
} from "../actions/action";

const INITIAL_STATE = {
  announcementData: null,
  isLoadingGetAnnouncement: false,
};

const AnnouncementState = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_ANNOUNCEMENT_REQUEST:
      return {
        ...state,
        isLoadingGetAnnouncement: action.payload.isLoadingGetAnnouncement,
      };
    case GET_ANNOUNCEMENT_SUCCESS:
      return {
        ...state,
        isLoadingGetAnnouncement: action.payload.isLoadingGetAnnouncement,
        announcementData: action.payload.announcementData,
      };
    case GET_ANNOUNCEMENT_FAILURE:
      return {
        ...state,
        isLoadingGetAnnouncement: action.payload.isLoadingGetAnnouncement,
      };
    default:
      return state;
  }
};

export default AnnouncementState;
