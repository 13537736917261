const accountStyles = (theme: any) => ({
  sectionTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    color: "#C90",
  },
  background: {
    backgroundColor: "#3f3f3f",
    minHeight: "100vh",
  },

  sectionSubtitle: {
    fontSize: "18px",
    color: "#F3F3F3 !important",
    fontWeight: "bold !important",
  },

  profileButton: {
    fontSize: "16px !important",
    backgroundColor: "#C90 !important",
    fontWeight: "bold !important",
    color: "#F3F3F3 !important",
  },
  inputClass: {
    backgroundColor: "#fcfcfc !important",
    "& .MuiOutlinedInput-notchedOutline": {
      //border:"2px solid #C90 !important",
      borderColor: "#C90 !important",
      "& legend": {
        //border:"2px solid #C90 !important"
        borderColor: "#C90 !important",
      },
    },
  },

  inputLabelClass: {
    color: "#C90 !important",
  },

  fieldError: {
    color: "red",
    fontWeight: "700",
    fontSize: "14px",
  },

  tableClass:{
    backgroundColor: "#F3F3F3 !important",
  },

  accountTab: {
    "& button.Mui-selected": {
      color: "#C90 !important",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#C90 !important",
    },
    "& .MuiButtonBase-root": {
      fontSize: "18px !important",
      fontWeight: "bold !important",
      color: "#F3F3F3 !important",
    },
  },

  inputBold: {
    "& .MuiInputBase-input": {
      fontWeight: "700",
    },
  },

  debit: {
    color: "#1FD668 !important",
    fontWeight: "700 !important",
  },

  credit: {
    color: "red !important",
    fontWeight: "700 !important",
  },

  cardCss: {
    backgroundColor: "#202626 !important",
    border: "2px solid #C90 !important",
  },
});

export default accountStyles;
