import "../../assets/home.scss";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import { Button, CardActionArea, CardActions } from "@mui/material";
import COMPETE10 from "../../assets/images/games/compete10.png";
import bc from "../../assets/images/games/bc.png";
import bjpk10 from "../../assets/images/games/bjpk10.png";
import fa from "../../assets/images/games/fa.png";
import fl from "../../assets/images/games/fl.png";
import hkms from "../../assets/images/games/hkms.png";
import jp8 from "../../assets/images/games/jp8.png";
import ml from "../../assets/images/games/ml.png";
import tjsl from "../../assets/images/games/tjsl.png";
import Grid from "@mui/material/Grid";
import { lottery } from "../../constant";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import lotteryImg from "../../assets/images/lottery.png";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ShowSnackbar } from "../../actions/snackbar";
import { useEffect } from "react";
import { GetAllAnnouncement } from "../../actions/announcement";
import moment from "moment";
import { useTranslation } from "react-i18next";
import HOMESECTIONIMAGE from "../../assets/images/home.png";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const announcementModel = useAppSelector((state) => state.announcement);
  const { i18n, t } = useTranslation();

  const handleRedirectToGame = (url: string) => {
    if (url === "#") {
      dispatch(ShowSnackbar(t("COMING_SOON_TEXT"), "success"));
    } else {
      navigate(url);
    }
  };

  const games = [
    { image: COMPETE10, game_name: t("COMPETE_10_TEXT"), link_to: lottery },
    { image: bjpk10, game_name: t("BEIJING_PK10_TEXT"), link_to: "#" },
    { image: tjsl, game_name: t("TJSL_TEXT"), link_to: "#" },
    { image: fl, game_name: t("FL_TEXT"), link_to: "#" },
    { image: ml, game_name: t("ML_TEXT"), link_to: "#" },
    { image: hkms, game_name: t("HKMS_TEXT"), link_to: "#" },
    { image: fa, game_name: t("FA_TEXT"), link_to: "#" },
    { image: bc, game_name: t("BC_TEXT"), link_to: "#" },
    { image: jp8, game_name: t("JP8_TEXT"), link_to: "#" },
  ];

  useEffect(() => {
    dispatch(GetAllAnnouncement());
  }, [dispatch]);
  return (
    <>
      <section className="homeSectionTopBackground">
        <div>
          <p className="homeSectionTitle">
            <span className="homeSectionPlayTitle">PLAY</span>{" "}
            <span className="homeSectionWithTitle">WITH,</span>{" "}
            <span className="homeSectionParadiseTitle">GLOBALWIN99</span>
          </p>
        </div>
        <div className="imageDiv">
          <img src={HOMESECTIONIMAGE} alt="home" width="60%" />
        </div>
        <div>
          <p className="homeSectionSubTitle1">
            {t("HOME_PAGE_SECTION_TEXT")}
          </p>
        </div>
        <div>
          <p className="homeSectionSubTitle2">
            {t("HOME_PAGE_SECTION_TEXT2")}
          </p>
        </div>
        <div>
          <p className="homeSectionSubTitle3">
            {t("HOME_PAGE_SECTION_TEXT3")}
          </p>
        </div>
        {/* <div>
          <img src={HOMESECTIONIMAGE} alt="home" />
        </div> */}
      </section>
      <div className="homeSectionBackground">
        <Container>
          {announcementModel?.announcementData?.data?.length > 0 ? (
            <section>
              <Divider
                sx={{
                  "&::before, &::after": {
                    borderTop: "4px solid #d3d3d3",
                  },
                }}
              >
                <p className="gameText">{t("ANNOUNCEMENT_TEXT")}</p>
              </Divider>
              <div className="announcementDiv">
                <Card className="announcementCard">
                  {announcementModel?.announcementData?.data?.map(
                    (item: any, index: number) => {
                      return (
                        <div key={index}>
                          <p className="announcementDate">
                            {moment(item.createdAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )}
                          </p>
                          <p className="announcementText">{item.description}</p>
                          <Divider />
                        </div>
                      );
                    }
                  )}
                </Card>
              </div>
            </section>
          ) : null}
          <section className="pb-1">
            <Divider
              sx={{
                "&::before, &::after": {
                  borderTop: "4px solid #d3d3d3",
                },
              }}
            >
              <p className="gameText">{t("GAME_WE_PROVIDE_TEXT")}</p>
            </Divider>
            <div className="gameDiv">
              {games.map((item, index) => {
                return (
                  <Card className="homeGameCard mb-2" key={index}>
                    <Grid container className="textCenter">
                      <Grid item md={4} xs={12}>
                        <div className="p-1">
                          <img src={item.image} alt="game" />
                        </div>
                      </Grid>
                      <Grid item md={4} xs={12} className="m-auto">
                        <div>
                          <p className="gameName">{item.game_name}</p>
                        </div>
                      </Grid>
                      <Grid item md={4} xs={12} className="m-auto">
                        <div className="mb-1">
                          <Button
                            size="large"
                            className="playNowBttn"
                            onClick={() => handleRedirectToGame(item.link_to)}
                          >
                            {t("PLAY_NOW_TEXT")}
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                );
              })}
            </div>
          </section>
          <section className="mt-2 pb-2">
            <Card className="introductionCard">
              <Grid container spacing={2}>
                <Grid item md={4} xs={12}>
                  <div className="p-1">
                    <img
                      src={lotteryImg}
                      width="100%"
                      height="auto"
                      alt="icon"
                    />
                  </div>
                </Grid>
                <Grid item md={8} xs={12}>
                  <div className="p-1 mt-3">
                    <p className="gameText">{t("SOUTHEST_ASIAN_TEXT")}</p>
                    <p className="bettingPlatFormText">{t("BETTING_PLATFORM_TEXT")}</p>
                    <p className="announcementText">
                      {t("PLATFORM_INTRODUCTION_TEXT")}
                    </p>
                  </div>
                </Grid>
              </Grid>
              <Divider />
              <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                  <div className="p-1">
                    <p className="linearText">{t("RECHARGE_TEXT")}</p>
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress
                        variant="determinate"
                        value={70}
                        className="linearProgress"
                      />
                    </Box>
                    <p className="fastResponseText">{t("FAST_RESPONSE")}</p>
                  </div>
                </Grid>
                <Grid item md={6} xs={12}>
                  <div className="p-1">
                    <p className="linearText">{t("WITHDRAWAL_TEXT")}</p>
                    <Box sx={{ width: "100%" }}>
                      <LinearProgress
                        variant="determinate"
                        value={70}
                        className="linearProgress"
                      />
                    </Box>
                    <p className="fastResponseText">{t("FAST_RESPONSE")}</p>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </section>
        </Container>
      </div>
    </>
  );
};

export default Home;
