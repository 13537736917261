import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import logo from "../../assets/images/pay.png";
import { makeStyles } from "@mui/styles";
import registerStyles from "../../assets/register";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { LoginRequest } from "../../interfaces/interface";
import { Link } from "react-router-dom";
import { home, register_page } from "../../constant";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { Login } from "../../actions/user";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { isUserExistInLocalStorage } from "../../actions/user";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const useStyles = makeStyles(registerStyles);

const LoginPage = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userSelector = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const localStorageUser = isUserExistInLocalStorage();
  const isAuthenticated =
    Object.keys(localStorageUser).length > 0 ? true : false;

  const { i18n, t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const handleLoginSubmit = (values: any) => {
    const data: LoginRequest = {
      name: values.name,
      password: values.password,
    };

    dispatch(Login(data, navigate));
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <div className={classes.loginRegisterBackground}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={userSelector?.isLoadingLogin}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="sm">
        <div className="textCenter pt-2">
          <img src={logo} height={120} width="100%" alt="logo" />
          <div>
            <p className={classes.accountText}>{t("ACCOUNT_LOGIN_TEXT")}</p>
          </div>
          <form onSubmit={handleSubmit(handleLoginSubmit)}>
            <div>
              <TextField
                fullWidth
                margin="dense"
                label={t("NAME_FORM_TEXT")}
                variant="outlined"
                InputProps={{ className: classes.inputClass }}
                InputLabelProps={{ className: classes.inputLabelClass }}
                {...register("name", { required: t("NAME_FORM_REQUIRED") })}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <div className="textLeft">
                    <span className={classes.fieldError}>{message}</span>
                  </div>
                )}
              />
            </div>
            <div className="mt-1">
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                margin="dense"
                label={t("PASSWORD_FORM_TEXT")}
                variant="outlined"
                InputProps={{
                  className: classes.inputClass,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ className: classes.inputLabelClass }}
                {...register("password", {
                  required: t("PASSWORD_FORM_REQUIRED"),
                })}
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => (
                  <div className="textLeft">
                    <span className={classes.fieldError}>{message}</span>
                  </div>
                )}
              />
            </div>
            <div className="mt-2">
              <Button
                variant="contained"
                size="large"
                fullWidth
                type="submit"
                className={classes.buttonClass}
              >
                {t("LOGIN_BUTTON_TEXT")}
              </Button>
            </div>
          </form>
          <div className="textLeft">
            <p className={classes.registerAccountText}>
              {t("HAVE_ACCOUNT_TEXT")}{" "}
              <Link to={register_page}>
                <span className={classes.registerLink}>
                  {t("REGISTER_BUTTON_TEXT")}
                </span>
              </Link>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LoginPage;
