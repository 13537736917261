import {
  GET_TODAY_TRANSACTION_FAILURE,
  GET_TODAY_TRANSACTION_REQUEST,
  GET_TODAY_TRANSACTION_SUCCESS,
  GET_TRANSACTION_RECORD_FAILURE,
  GET_TRANSACTION_RECORD_REQUEST,
  GET_TRANSACTION_RECORD_SUCCESS,
} from "./action";
import axios from "../axiosConfig";
const TRANSACTION_ENDPOINT = "/api/Transaction";

export const GetTransactionRecord = (userId: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_TRANSACTION_RECORD_REQUEST,
      payload: { isLoadingGetTransaction: true },
    });

    axios
      .get(`${TRANSACTION_ENDPOINT}/${userId}`)
      .then((res) => {
        dispatch({
          type: GET_TRANSACTION_RECORD_SUCCESS,
          payload: {
            transactionRecord: res?.data,
            isLoadingGetTransaction: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_TRANSACTION_RECORD_FAILURE,
          payload: {
            isLoadingGetTransaction: false,
          },
        });
      });
  };
};

export const GetTodayTransaction = (userId: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_TODAY_TRANSACTION_REQUEST,
      payload: { isLoadingGetTodayTransaction: true },
    });

    axios
      .get(`${TRANSACTION_ENDPOINT}/GetTodayTransaction?id=${userId}`)
      .then((res) => {
        dispatch({
          type: GET_TODAY_TRANSACTION_SUCCESS,
          payload: {
            todayTransaction: res?.data,
            isLoadingGetTodayTransaction: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_TODAY_TRANSACTION_FAILURE,
          payload: {
            isLoadingGetTodayTransaction: false,
          },
        });
      });
  };
};
