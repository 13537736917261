import React, { useEffect, useRef } from "react";
import "./App.css";
import MainRoute from "./routes";
import BeforeLoginHeader from "./components/BeforeLoginHeader";
import { useLocation } from "react-router-dom";
import { default_page, home, login, register_page } from "./constant";
import Footer from "./components/Footer";
import AfterLoginHeader from "./components/AfterLoginHeader";
import { CheckUserAccStatus, isUserExistInLocalStorage } from "./actions/user";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { useAppDispatch } from "./hooks";

function App() {
  const tawkRef = useRef<any>();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const localStorageUser = isUserExistInLocalStorage();
  const isUserLogin = Object.keys(localStorageUser).length > 0 ? true : false;
  const hideWithoutLoginHeader =
    location?.pathname === login || location?.pathname === register_page
      ? true
      : false;
  const isHomePage =
    location?.pathname === home || location?.pathname === default_page
      ? true
      : false;

  const onHandleTawkMessenger = () => {
    if (tawkRef?.current) {
      tawkRef?.current?.maximize();
    }
  };

  useEffect(() => {
    dispatch(CheckUserAccStatus());
  }, []);

  return (
    <>
      {isUserLogin ? <AfterLoginHeader /> : <BeforeLoginHeader />}
      <TawkMessengerReact
        propertyId={process.env.REACT_APP_TAWK_PROPERTY_ID}
        widgetId={process.env.REACT_APP_TAWK_WIDGET_ID}
        ref={tawkRef}
      />
      <MainRoute onHandleTawkMessenger={onHandleTawkMessenger} />
      {isHomePage ? <Footer /> : null}
    </>
  );
}

export default App;
