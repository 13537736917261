import {
  GET_TRANSACTION_RECORD_REQUEST,
  GET_TRANSACTION_RECORD_SUCCESS,
  GET_TRANSACTION_RECORD_FAILURE,
  GET_TODAY_TRANSACTION_REQUEST,
  GET_TODAY_TRANSACTION_SUCCESS,
  GET_TODAY_TRANSACTION_FAILURE,
} from "../actions/action";

const INITIAL_STATE = {
  transactionRecord: null,
  isLoadingGetTransaction: false,
  isLoadingGetTodayTransaction: false,
  todayTransaction: null,
};

const TransactionState = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_TRANSACTION_RECORD_REQUEST:
      return {
        ...state,
        isLoadingGetTransaction: action.payload.isLoadingGetTransaction,
      };
    case GET_TRANSACTION_RECORD_SUCCESS:
      return {
        ...state,
        isLoadingGetTransaction: action.payload.isLoadingGetTransaction,
        transactionRecord: action.payload.transactionRecord,
      };
    case GET_TRANSACTION_RECORD_FAILURE:
      return {
        ...state,
        isLoadingGetTransaction: action.payload.isLoadingGetTransaction,
      };
    case GET_TODAY_TRANSACTION_REQUEST:
      return {
        ...state,
        isLoadingGetTodayTransaction:
          action.payload.isLoadingGetTodayTransaction,
      };
    case GET_TODAY_TRANSACTION_SUCCESS:
      return {
        ...state,
        isLoadingGetTodayTransaction:
          action.payload.isLoadingGetTodayTransaction,
        todayTransaction: action.payload.todayTransaction,
      };
    case GET_TODAY_TRANSACTION_FAILURE:
      return {
        ...state,
        isLoadingGetTodayTransaction:
          action.payload.isLoadingGetTodayTransaction,
      };
    default:
      return state;
  }
};

export default TransactionState;
