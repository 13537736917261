import React, { Fragment, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../../assets/images/pay.png";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "../../assets/header.scss";
import MenuIcon from "@mui/icons-material/Menu";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useLocation } from "react-router-dom";
import { login, register_page } from "../../constant";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

const drawerWidth = 240;

const BeforeLoginHeader = (props: Props) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // const { i18n, t } = useTranslation();
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(
    language === "en-US" ? "en" : language
  );
  const navItems = [
    { path: login, name: t("NAV_LOGIN_TEXT") },
    { path: register_page, name: t("NAV_REGISTER_TEXT") },
    //   { path: home, name: "HOME" },
    //   { path: aboutus, name: "ABOUT US" },
    //   { path: solutions, name: "SOLUTIONS" },
  ];

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentLanguage(event.target.value as string);
    changeLanguage(event.target.value);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <img src={Logo} height={50} width="100%" alt="logo" />
      <Divider />
      <List>
        {navItems.map((item, index) => {
          return (
            <Fragment key={index}>
              <ListItem disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <ListItemText className="accountNavMenuMobile">
                    <a href={item.path} className="accountNavAMobile">
                      {item.name}
                    </a>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider />
            </Fragment>
          );
        })}
        <div>
          <span className="mr-1 languageText">{t("LANGUAGE_TEXT")}</span>
          <Select
            value={currentLanguage}
            className="languageSelect"
            onChange={handleChange}
          >
            <MenuItem value="en">en</MenuItem>
            <MenuItem value="cn">cn</MenuItem>
          </Select>
        </div>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        elevation={4}
        className="appBarColor appBarBoxShadow"
      >
        <Toolbar>
          <IconButton
            className="headerIconBttn"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img src={Logo} height={60} width={250} alt="logo" />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "none", lg: "block" },
            }}
          >
            {navItems.map((item, index) => {
              return (
                <Fragment key={index}>
                  {item.path === login ? (
                    <Button className="loginNavMenu">
                      <a href={item.path} className="loginNavA">
                        {item.name}
                      </a>
                    </Button>
                  ) : (
                    <Button className="registerNavMenu">
                      <a href={item.path} className="registerNavA">
                        {item.name}
                      </a>
                    </Button>
                  )}
                </Fragment>
              );
            })}
            <span>
              <Select
                value={currentLanguage}
                className="languageSelect"
                onChange={handleChange}
              >
                <MenuItem value="en">en</MenuItem>
                <MenuItem value="cn">cn</MenuItem>
              </Select>
            </span>
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#000000",
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
};

export default BeforeLoginHeader;
