import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { removeSnackbar } from "../../actions/snackbar";

let displayed = [] as any;

const Notifier = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(
        (store: any) => store.snackbar.notifications || []
    );
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id: any) => {
        displayed = [...displayed, id];
    };

    const removeDisplayed = (id: any) => {
        displayed = [...displayed.filter((key: any) => id !== key)];
    };

    useEffect(() => {
        notifications.find((x: any) => x.key != null) &&
            notifications.forEach(
                // { key, message, options = {}, dismissed = false }
                ({ key, message, options = {}, dismissed = false }: { key: any, message: any, options: any, dismissed: boolean }) => {
                    if (dismissed) {
                        closeSnackbar(key);
                        return;
                    }

                    if (displayed.includes(key)) return;

                    enqueueSnackbar(message, {
                        key,
                        ...options,
                        onClose: (event, reason, myKey) => {
                            if (options.onClose) {
                                options.onClose(event, reason, myKey);
                            }
                        },
                        onExited: (event, myKey) => {
                            dispatch(removeSnackbar(myKey));
                            removeDisplayed(myKey);
                        },
                    });

                    storeDisplayed(key);
                }
            );
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;