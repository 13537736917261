import axios from "axios";
import { Logout, isUserExistInLocalStorage } from "./actions/user";
import { login } from "./constant";

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
  },
  baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.request.use((config) => {
  const localStorageUser = isUserExistInLocalStorage();
  const token = localStorageUser?.accessToken;
  config.headers["Authorization"] = token ? `Bearer ${token}` : "";
  return config;
});

instance.interceptors.response.use(
  (res) => {
    // Add configurations here
    return res;
  },
  (err) => {
    return Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    return new Promise((resolve, reject) => {
      if (error.response === undefined || error.response.status !== 401) {
        return reject(error.response);
      } else {
        axios.interceptors.response.eject(instance.length);
        console.log(error);
        Logout();
        window.location.href = login;
        return reject(error);
      }
    });
  }
);

export default instance;
