import loginImage from "../assets/images/login2.jpg";
const registerStyles = (theme: any) => ({
  loginRegisterBackground: {
    backgroundImage: `url('${loginImage}')`,
    backgroundSize: "cover",
    //backgroundColor: "#3f3f3f",
    minHeight: "100vh",
  },

  accountText: {
    fontSize: "22px",
    fontWeight: "700",
    color: "#C90",
  },

  inputClass: {
    borderRadius: "0 !important",
    backgroundColor: "#fcfcfc !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#C90 !important",
      "& legend": {
        borderColor: "#C90 !important",
      },
    },
  },

  inputLabelClass: {
    color: "#C90 !important",
  },

  buttonClass: {
    backgroundColor: "#C90 !important",
    color: "#fcfcfc",
  },

  fieldError: {
    color: "red",
    fontWeight: "700",
    fontSize: "14px",
  },

  registerAccountText: {
    fontSize: "16px",
    color: "#fcfcfc",
  },

  registerLink: {
    color: "#C90 !important",
    textDecoration: "underline",
    fontWeight: "700",
  },
});

export default registerStyles;
