import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import logo from "../../assets/images/pay.png";
import { makeStyles } from "@mui/styles";
import registerStyles from "../../assets/register";
import Button from "@mui/material/Button";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { RegisterRequest } from "../../interfaces/interface";
import { Link } from "react-router-dom";
import { login, register_page, home } from "../../constant";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { Register } from "../../actions/user";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { isUserExistInLocalStorage } from "../../actions/user";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles(registerStyles);

const RegisterPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const referralCode = urlParams.get("referral");
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userSelector = useAppSelector((state) => state.user);
  const navigate = useNavigate();
  const localStorageUser = isUserExistInLocalStorage();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const isAuthenticated =
    Object.keys(localStorageUser).length > 0 ? true : false;

  const { i18n, t } = useTranslation();

  // const regex = new RegExp(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!*@#$%^&+=])(?=.{8,})/
  // );
  const regex = new RegExp(/^.{6,}$/);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      password: "",
      confirmPassword: "",
      referralCode: referralCode,
      role: "User",
    },
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleRegisterSubmit = (values: any) => {
    const data: RegisterRequest = {
      name: values.name,
      password: values.password,
      confirmPassword: values.confirmPassword,
      referralCode: values.referralCode,
      role: values.role,
    };

    dispatch(Register(data, navigate));
  };

  return (
    <div className={classes.loginRegisterBackground}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={userSelector?.isLoadingRegister}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="sm">
        <div className="textCenter pt-2">
          <img src={logo} height={120} width="100%" alt="logo" />
          <div>
            <p className={classes.accountText}>{t("ACCOUNT_REGISTER_TEXT")}</p>
          </div>
          <form onSubmit={handleSubmit(handleRegisterSubmit)}>
            <div>
              <TextField
                fullWidth
                margin="dense"
                label={t("NAME_FORM_TEXT")}
                variant="outlined"
                InputProps={{ className: classes.inputClass }}
                InputLabelProps={{ className: classes.inputLabelClass }}
                {...register("name", { required: t("NAME_FORM_REQUIRED") })}
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => (
                  <div className="textLeft">
                    <span className={classes.fieldError}>{message}</span>
                  </div>
                )}
              />
            </div>
            <div className="mt-1">
              <TextField
                fullWidth
                type={showPassword ? "text" : "password"}
                margin="dense"
                label={t("PASSWORD_FORM_TEXT")}
                variant="outlined"
                InputProps={{
                  className: classes.inputClass,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ className: classes.inputLabelClass }}
                {...register("password", {
                  required: t("PASSWORD_FORM_REQUIRED"),
                  validate: (val: string) => {
                    if (!regex.test(val)) {
                      return t("PASSWORD_REGEX_TEXT");
                    }
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="password"
                render={({ message }) => (
                  <div className="textLeft">
                    <span className={classes.fieldError}>{message}</span>
                  </div>
                )}
              />
            </div>
            <div className="mt-1">
              <TextField
                fullWidth
                type={showConfirmPassword ? "text" : "password"}
                margin="dense"
                label={t("CONFIRM_PASSWORD_FORM_TEXT")}
                variant="outlined"
                InputProps={{
                  className: classes.inputClass,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ className: classes.inputLabelClass }}
                {...register("confirmPassword", {
                  required: t("CONFIRM_PASSWORD_FORM_REQUIRED"),
                  validate: (val: string) => {
                    if (watch("password") !== val) {
                      return t("CONFIRM_PASSWORD_NOT_MATCH_TEXT");
                    }
                  },
                })}
              />
              <ErrorMessage
                errors={errors}
                name="confirmPassword"
                render={({ message }) => (
                  <div className="textLeft">
                    <span className={classes.fieldError}>{message}</span>
                  </div>
                )}
              />
            </div>
            <div className="mt-1">
              <TextField
                fullWidth
                margin="dense"
                label={t("REFERRAL_CODE_FORM_TEXT")}
                variant="outlined"
                InputProps={{ className: classes.inputClass }}
                InputLabelProps={{ className: classes.inputLabelClass }}
                {...register("referralCode", {
                  required: t("REFERRAL_CODE_FORM_REQUIRED"),
                })}
              />
              <ErrorMessage
                errors={errors}
                name="referralCode"
                render={({ message }) => (
                  <div className="textLeft">
                    <span className={classes.fieldError}>{message}</span>
                  </div>
                )}
              />
            </div>
            <div className="mt-2">
              <Button
                variant="contained"
                size="large"
                fullWidth
                type="submit"
                className={classes.buttonClass}
              >
                {t("REGISTER_BUTTON_TEXT")}
              </Button>
            </div>
          </form>
          <div className="textLeft">
            <p className={classes.registerAccountText}>
              {t("ALREADY_HAVE_ACCOUNT_TEXT")}{" "}
              <Link to={login}>
                <span className={classes.registerLink}>
                  {t("LOGIN_BUTTON_TEXT")}
                </span>
              </Link>
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default RegisterPage;
