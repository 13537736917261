import Card from "@mui/material/Card";
import { Container, Grid } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { makeStyles } from "@mui/styles";
import styles from "../../assets/account";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  GetUserInfo,
  UpdatePassword,
  isUserExistInLocalStorage,
  CheckUserAccStatus,
} from "../../actions/user";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { GetBettingRecord } from "../../actions/bet";
import {
  GetTodayTransaction,
  GetTransactionRecord,
} from "../../actions/transaction";
import moment from "moment";
import { DEBIT } from "../../constant";
import { useTranslation } from "react-i18next";
import AgentCenter from "./components/agent-center";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

const useStyles = makeStyles(styles);

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface ChildProps {
  onHandleTawkMessenger: () => void;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccountPage: React.FC<ChildProps> = ({ onHandleTawkMessenger }) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      password: "",
      confirmPassword: "",
      oldPassword: "",
    },
  });
  const dispatch = useAppDispatch();
  const userSelector = useAppSelector((state) => state.user);
  const betSelector = useAppSelector((state) => state.bet);
  const transactionSelector = useAppSelector((state) => state.transaction);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);

  const handleMouseDownOldPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useEffect(() => {
    const localStorageUser = isUserExistInLocalStorage();
    if (localStorageUser?.userId) {
      dispatch(GetUserInfo());
      dispatch(GetBettingRecord(localStorageUser?.userId));
      dispatch(GetTransactionRecord(localStorageUser?.userId));
      dispatch(GetTodayTransaction(localStorageUser?.userId));
    }
  }, [dispatch]);

  useEffect(() => {
    const localStorageUser = isUserExistInLocalStorage();
    if (localStorageUser?.userId) {
      dispatch(CheckUserAccStatus());
    }
  }, [dispatch, value]);
  // const regex = new RegExp(
  //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!*@#$%^&+=])(?=.{8,})/
  // );

  const regex = new RegExp(/^.{6,}$/);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleUpdatePassword = (values: any) => {
    const localStorageUser = isUserExistInLocalStorage();

    const data = {
      oldPassword: values.oldPassword,
      password: values.password,
      userId: localStorageUser?.userId,
    };

    dispatch(UpdatePassword(data));
  };

  return (
    <div className={classes.background}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={userSelector?.isLoadingUpdatePassword}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container className="pt-1">
        <Card className={classes.cardCss}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                //   className={classes.accountTab}
                classes={{ root: classes.accountTab }}
              >
                <Tab label={t("PROFILE_TEXT")} {...a11yProps(0)} />
                <Tab label={t("BETTING_RECORD_TEXT")} {...a11yProps(1)} />
                <Tab label={t("TRANSACTION_RECORD_TEXT")} {...a11yProps(2)} />
                <Tab label={t("TODAY_PROFIT")} {...a11yProps(3)} />
                {userSelector?.userInfo?.userRoles?.includes("Agent") ? (
                  <Tab label={t("AGENT_CENTER")} {...a11yProps(4)} />
                ) : null}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <section>
                <p className={classes.sectionTitle}>{t("PROFILE_TEXT")}</p>
                <Grid container>
                  <Grid item md={4} xs={12}>
                    <p className={classes.sectionSubtitle}>
                      {t("NAME_FORM_TEXT")}
                    </p>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <TextField
                      id="name"
                      InputProps={{
                        className: classes.inputClass,
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabelClass,
                      }}
                      variant="outlined"
                      value={userSelector?.userInfo?.name || ""}
                      size="medium"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className="mt-1">
                    <p className={classes.sectionSubtitle}>
                      {t("REFERRAL_CODE_FORM_TEXT")}
                    </p>
                  </Grid>
                  <Grid item md={8} xs={12} className="mt-1">
                    <TextField
                      id="referral"
                      InputProps={{
                        className: classes.inputClass,
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabelClass,
                      }}
                      variant="outlined"
                      value={userSelector?.userInfo?.referralCode || ""}
                      size="medium"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={4} xs={12} className="mt-1">
                    <p className={classes.sectionSubtitle}>
                      {t("WASH_BALANCE_TEXT")}
                    </p>
                  </Grid>
                  <Grid item md={8} xs={12} className="mt-1">
                    <TextField
                      InputProps={{
                        className: classes.inputClass,
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabelClass,
                      }}
                      variant="outlined"
                      value={userSelector?.userInfo?.washBalance || ""}
                      size="medium"
                      fullWidth
                    />
                  </Grid>
                </Grid>
                <p className={classes.sectionTitle}>
                  {t("CURRENT_BALANCE_TEXT")}
                </p>
                <Grid container spacing={4}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="profit"
                      InputProps={{
                        className: classes.inputClass,
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        className: classes.inputLabelClass,
                      }}
                      variant="outlined"
                      value={userSelector?.userInfo?.totalProfit || ""}
                      size="medium"
                      fullWidth
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justifyCenter"
                    >
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.profileButton}
                        onClick={onHandleTawkMessenger}
                      >
                        {t("DEPOSIT_TEXT")}
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        className={classes.profileButton}
                        onClick={onHandleTawkMessenger}
                      >
                        {t("WITHDRAWAL_TEXT")}
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </section>
              <Divider className="mt-2" />
              <section>
                <form onSubmit={handleSubmit(handleUpdatePassword)}>
                  <p className={classes.sectionTitle}>
                    {t("CHANGE_PASSWORD_TEXT")}
                  </p>
                  <Grid container>
                    <Grid item xs={12} md={4}>
                      <p className={classes.sectionSubtitle}>
                        {t("OLD_PASSWORD_TEXT")}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        type={showOldPassword ? "text" : "password"}
                        margin="dense"
                        label={t("OLD_PASSWORD_TEXT")}
                        variant="outlined"
                        InputProps={{
                          className: classes.inputClass,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowOldPassword}
                                onMouseDown={handleMouseDownOldPassword}
                                edge="end"
                              >
                                {showOldPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          className: classes.inputLabelClass,
                        }}
                        {...register("oldPassword", {
                          required: t("PASSWORD_FORM_REQUIRED"),
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="oldPassword"
                        render={({ message }) => (
                          <div className="textLeft">
                            <span className={classes.fieldError}>
                              {message}
                            </span>
                          </div>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <p className={classes.sectionSubtitle}>
                        {t("PASSWORD_FORM_TEXT")}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        type={showPassword ? "text" : "password"}
                        margin="dense"
                        label={t("PASSWORD_FORM_TEXT")}
                        variant="outlined"
                        InputProps={{
                          className: classes.inputClass,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          className: classes.inputLabelClass,
                        }}
                        {...register("password", {
                          required: t("PASSWORD_FORM_REQUIRED"),
                          validate: (val: string) => {
                            if (!regex.test(val)) {
                              return t("PASSWORD_REGEX_TEXT");
                            }
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="password"
                        render={({ message }) => (
                          <div className="textLeft">
                            <span className={classes.fieldError}>
                              {message}
                            </span>
                          </div>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <p className={classes.sectionSubtitle}>
                        {t("CONFIRM_PASSWORD_FORM_TEXT")}
                      </p>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextField
                        fullWidth
                        type={showConfirmPassword ? "text" : "password"}
                        margin="dense"
                        label={t("CONFIRM_PASSWORD_FORM_TEXT")}
                        variant="outlined"
                        InputProps={{
                          className: classes.inputClass,
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowConfirmPassword}
                                onMouseDown={handleMouseDownConfirmPassword}
                                edge="end"
                              >
                                {showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                        InputLabelProps={{
                          className: classes.inputLabelClass,
                        }}
                        {...register("confirmPassword", {
                          required: t("CONFIRM_PASSWORD_FORM_REQUIRED"),
                          validate: (val: string) => {
                            if (watch("password") !== val) {
                              return t("CONFIRM_PASSWORD_NOT_MATCH_TEXT");
                            }
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="confirmPassword"
                        render={({ message }) => (
                          <div className="textLeft">
                            <span className={classes.fieldError}>
                              {message}
                            </span>
                          </div>
                        )}
                      />
                    </Grid>
                  </Grid>
                  <div className="mt-1">
                    <Button
                      variant="contained"
                      type="submit"
                      className={classes.profileButton}
                    >
                      {t("UPDATE_BUTTON_TEXT")}
                    </Button>
                  </div>
                </form>
              </section>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <p className={classes.sectionTitle}>{t("BETTING_RECORD_TEXT")}</p>
              <TableContainer
                component={Paper}
                style={{ maxHeight: 500 }}
                className={classes.tableClass}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("PERIOD_TEXT")}</TableCell>
                      <TableCell>{t("BETTING_NUMBER_TEXT")}</TableCell>
                      <TableCell>{t("TOTAL_TEXT")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {betSelector?.userBettingRecord?.data?.length > 0
                      ? betSelector?.userBettingRecord?.data?.map(
                          (item: any, index: number) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {item.betReference}
                                </TableCell>
                                <TableCell>{item.betNumber}</TableCell>
                                <TableCell>{item.multiplies}</TableCell>
                              </TableRow>
                            );
                          }
                        )
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <p className={classes.sectionTitle}>
                {t("TRANSACTION_RECORD_TEXT")}
              </p>
              <TableContainer
                component={Paper}
                style={{ maxHeight: 500 }}
                className={classes.tableClass}
              >
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("DATE_TEXT")}</TableCell>
                      <TableCell>{t("SUMMARY_TEXT")}</TableCell>
                      <TableCell>{t("TOTAL_TEXT")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {transactionSelector?.transactionRecord?.data?.length > 0
                      ? transactionSelector?.transactionRecord?.data?.map(
                          (item: any, index: number) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell component="th" scope="row">
                                  {moment(item.createdAt).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                  )}
                                </TableCell>
                                <TableCell
                                  className={
                                    item.walletNote === DEBIT
                                      ? classes.debit
                                      : classes.credit
                                  }
                                >
                                  {item.description}
                                </TableCell>
                                <TableCell
                                  className={
                                    item.walletNote === DEBIT
                                      ? classes.debit
                                      : classes.credit
                                  }
                                >
                                  {item.amount}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )
                      : null}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <p className={classes.sectionTitle}>{t("TODAY_PROFIT")}</p>
              <Grid container spacing={2} className="textCenter">
                <Grid item xs={12} md={4}>
                  <Card className={classes.cardCss}>
                    <p className={classes.sectionTitle}>
                      {t("TOTAL_PROFIT_TEXT")}
                    </p>
                    <p className={classes.sectionSubtitle}>
                      {userSelector?.userInfo?.totalProfit}
                    </p>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card className={classes.cardCss}>
                    <p className={classes.sectionTitle}>
                      {t("BETTING_FUNDS_TEXT")}
                    </p>
                    <p className={classes.sectionSubtitle}>
                      {transactionSelector?.todayTransaction?.data?.amountToBet}
                    </p>
                  </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Card className={classes.cardCss}>
                    <p className={classes.sectionTitle}>{t("TOP_UP_TEXT")}</p>
                    <p className={classes.sectionSubtitle}>
                      {
                        transactionSelector?.todayTransaction?.data
                          ?.amountToDeposit
                      }
                    </p>
                  </Card>
                </Grid>
              </Grid>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <AgentCenter />
            </CustomTabPanel>
          </Box>
        </Card>
      </Container>
    </div>
  );
};

export default AccountPage;
