import "../../assets/footer.scss";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { i18n, t } = useTranslation();
  return (
    <footer className="footer">
      <Container maxWidth="lg">
        <Grid container>
          <Grid item md={12} xs={12} className="textCenter">
            <p className="footerText">{t("FOOTER_TEXT")}</p>
          </Grid>
          <Grid item md={12} xs={12} className="textCenter">
            <p className="footerCopyRight">
              &copy; Copyright 2023 GLOBALWIN99.com.co
            </p>
          </Grid>
          {/* <Grid item md={4} xs={12} className="mt-2">
            <img src={Logo} height={100} alt="logo" />
          </Grid>
          <Grid item md={4} xs={12}>
            <p className="footerTitle">COMPANY</p>
            <div className="mt-1">
              <a href={home} className="footerLink">
                HOME
              </a>
            </div>
            <div className="mt-1">
              <a href={aboutus} className="footerLink">
                ABOUT US
              </a>
            </div>
            <div className="mt-1">
              <a href={solutions} className="footerLink">
                SOLUTIONS
              </a>
            </div>
            <div className="mt-1">
              <a href={contactus} className="footerLink">
                CONTACT US
              </a>
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <p className="footerTitle">CONTACT INFO</p>
            <div className="mt-1">
              <span className="footerLink">
                Email: customer-service@dreampay31.com
              </span>
            </div>
          </Grid>
        </Grid>
        <Divider className="footerHr mt-2" />
        <Grid container>
          <Grid item md={12} xs={12} className="textCenter">
            <p className="footerCopyRight">
              &copy; Copyright 2023 DREAMPAY31.COM
            </p>
          </Grid>
        </Grid> */}
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
