import { Container, Grid } from "@mui/material";
import "../../assets/lottery.scss";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import { Button, CardActionArea, CardActions } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { Fragment, useEffect, useState } from "react";
import COMPETE10 from "../../assets/images/games/compete10.png";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { CreateUserBet, GetAllBet } from "../../actions/bet";
import CountDownTimer from "../../components/CountDownTimer";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  CheckUserAccStatus,
  GetUserInfo,
  isUserExistInLocalStorage,
} from "../../actions/user";
import Close from "@mui/icons-material/Close";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import styles from "../../assets/account";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(styles);

interface ChildProps {
  onHandleTawkMessenger: () => void;
}

const Lottery: React.FC<ChildProps> = ({ onHandleTawkMessenger }) => {
  const dispatch = useAppDispatch();
  const betSelector = useAppSelector((state) => state.bet);
  const BetNumbers = [
    { betNumber: "01" },
    { betNumber: "02" },
    { betNumber: "03" },
    { betNumber: "04" },
    { betNumber: "05" },
    { betNumber: "06" },
    { betNumber: "07" },
    { betNumber: "08" },
    { betNumber: "09" },
    { betNumber: "10" },
  ];
  const [betArray, setBetArray] = useState<any[]>([]);
  const [odds, setOdds] = useState<number>(1);
  const [modal, setModal] = useState<boolean>(false);
  const theme = useTheme();
  const isSM = useMediaQuery(theme.breakpoints.up("sm"));
  const userSelector = useAppSelector((state) => state.user);
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  // const [modalOdds, setModalOdds] = useState<number>(0);
  // const [currentMultiplies, setCurrentMultiplies] = useState<number>(-1);
  const style = {
    //position: "relative",
    //display: "inline-grid",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSM ? "600px" : "250px",
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  useEffect(() => {
    const localStorageUser = isUserExistInLocalStorage();
    if (localStorageUser?.userId) {
      dispatch(GetAllBet());
      dispatch(GetUserInfo());
      dispatch(CheckUserAccStatus());
    }
  }, [dispatch]);

  // useEffect(() => {
  //   setBetArray(betArray);
  // }, [currentMultiplies]);

  const handleClear = () => {
    setBetArray([]);
    setOdds(1);
    setModal(false);
  };

  const handleSubmitBet = (betArray: any) => {
    const localStorageUser = isUserExistInLocalStorage();
    if (Object.keys(localStorageUser).length > 0) {
      betArray.forEach((element: any) => {
        element["betId"] = betSelector?.betData?.data?.current?.id;
        element["userId"] = localStorageUser?.userId;
        element["betReference"] =
          betSelector?.betData?.data?.current?.betReference;
        element["betNumber"] = parseInt(element.betNumber);
      });

      const data = {
        requestModel: betArray,
      };

      dispatch(CreateUserBet(data));
    }
  };
  const handleManageBetArray = (item: any) => {
    const found = betArray.some((el) => el.betNumber === item.betNumber);
    if (!found) {
      //Add to new Item
      setBetArray((oldArray) => [...oldArray, item]);
    } else {
      //Remove the array item
      var filterArray = betArray.filter((x) => x.betNumber !== item.betNumber);
      setBetArray(filterArray);
    }
  };

  const handleCss = (item: any) => {
    var found = betArray?.some((x) => x.betNumber === item.betNumber);
    if (found) {
      return "betAvatarSelected";
    } else {
      return "betAvatar";
    }
  };

  const handleShowList = (betArray: any) => {
    betArray.forEach((element: any) => {
      element["betCount"] = 1;
      element["odds"] = 9.16;
      element["multiplies"] = odds;
      element["winningAmount"] = odds * 9.16;
    });
    // setModalOdds(odds);
    setModal(true);
  };

  // const handleSetModalOdds = (e: any, number: number, index: number) => {
  //   e.preventDefault();
  //   var targetOdds = parseInt(e.target.value);
  //   betArray.forEach((element: any) => {
  //     if (element.number === number) {
  //       element["multiplies"] = targetOdds;
  //       element["winningAmount"] = element.odds * targetOdds;
  //     }
  //   });
  //   setBetArray(betArray);
  //   // setCurrentMultiplies(targetOdds);
  // };

  const handleClose = () => {
    setBetArray([]);
    setOdds(1);
    setModal(false);
  };

  const handleSetOdds = (e: any) => {
    e.preventDefault();
    var targetOdds = parseInt(e.target.value);
    setOdds(targetOdds);
  };
  return (
    <div className="background">
      <Container>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={betSelector?.isLoadingGetAllBet}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <section className="pt-1">
          <Card className="lotteryProfileCard p-2">
            <Grid container>
              <Grid item md={12} xs={12}>
                <p className="lotteryProfileText">
                  {t("CURRENT_BALANCE_TEXT")}
                </p>
                <p className="lotteryProfileTextValue">
                  {userSelector?.userInfo?.totalProfit
                    ? userSelector?.userInfo?.totalProfit
                    : 0}
                </p>
              </Grid>
            </Grid>
            <Divider className="dividerColor" />
            <Grid container>
              <Grid item md={6} xs={6}>
                <p className="lotteryProfileText">{t("NAME_FORM_TEXT")}</p>
                <p className="lotteryProfileTextValue mt-0 mb-0">
                  {userSelector?.userInfo?.name}
                </p>
              </Grid>
              <Grid item md={6} xs={6}>
                <p className="lotteryProfileText">{t("WASH_BALANCE_TEXT")}</p>
                <p className="lotteryProfileTextValue mt-0 mb-0">
                  {userSelector?.userInfo?.washBalance}
                </p>
              </Grid>
            </Grid>
            <div className="mt-1">
              <Button
                variant="contained"
                size="large"
                className={classes.profileButton}
                onClick={() => {
                  dispatch(CheckUserAccStatus());
                  onHandleTawkMessenger();
                }}
              >
                {t("DEPOSIT_TEXT")}
              </Button>
            </div>
            <div className="mt-1">
              <Button
                variant="contained"
                size="large"
                className={classes.profileButton}
                onClick={() => {
                  dispatch(CheckUserAccStatus());
                  onHandleTawkMessenger();
                }}
              >
                {t("WITHDRAWAL_TEXT")}
              </Button>
            </div>
          </Card>
        </section>
        <section className="mt-1">
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Card
                className="lotteryCard textCenter height100"
                //sx={{ maxWidth: 345 }}
              >
                <p className="lotteryText">{t("COMPETE_10_TEXT")}</p>
                <img src={COMPETE10} alt="game" height={150} width={150} />
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                className="lotteryCard textCenter height100"
                //sx={{ maxWidth: 345 }}
              >
                <p className="lotteryText">
                  {t("CLOSING_BET_TEXT")}{" "}
                  <span className="redColorText">
                    {betSelector?.betData?.data?.current?.betReference}
                  </span>{" "}
                  {t("IN_TEXT")}
                </p>
                {betSelector?.betData?.data?.current?.endDate ? (
                  <CountDownTimer
                    targetDate={betSelector?.betData?.data?.current?.endDate}
                    betReference={
                      betSelector?.betData?.data?.current?.betReference
                    }
                  />
                ) : null}
                <Stack
                  direction="row"
                  spacing={2}
                  className="justifyCenter"
                ></Stack>
              </Card>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card
                className="lotteryCard textCenter height100"
                //sx={{ maxWidth: 345 }}
              >
                <p className="lotteryText">
                  {t("WINNING_AMOUNT_TEXT")}{" "}
                  <span className="redColorText">
                    {betSelector?.betData?.data?.previous?.[0]?.betReference}
                  </span>{" "}
                  {t("IS_TEXT")}:
                </p>
                <Stack direction="row" spacing={2} className="justifyCenter">
                  <Avatar className="betNumber">
                    {betSelector?.betData?.data?.previous?.[0]?.item1}
                  </Avatar>
                  <Avatar className="betNumber">
                    {betSelector?.betData?.data?.previous?.[0]?.item2}
                  </Avatar>
                  <Avatar className="betNumber">
                    {betSelector?.betData?.data?.previous?.[0]?.item3}
                  </Avatar>
                  <Avatar className="betNumber">
                    {betSelector?.betData?.data?.previous?.[0]?.item4}
                  </Avatar>
                  <Avatar className="betNumber">
                    {betSelector?.betData?.data?.previous?.[0]?.item5}
                  </Avatar>
                  <Avatar className="betNumber">
                    {betSelector?.betData?.data?.previous?.[0]?.item6}
                  </Avatar>
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  className="mt-1 justifyCenter"
                >
                  <Avatar className="betNumber">
                    {betSelector?.betData?.data?.previous?.[0]?.item7}
                  </Avatar>
                  <Avatar className="betNumber">
                    {betSelector?.betData?.data?.previous?.[0]?.item8}
                  </Avatar>
                  <Avatar className="betNumber">
                    {betSelector?.betData?.data?.previous?.[0]?.item9}
                  </Avatar>
                  <Avatar className="betNumber">
                    {betSelector?.betData?.data?.previous?.[0]?.item10}
                  </Avatar>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </section>
        <section className="mt-1">
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <form>
                <Card className="lotteryCard textCenter height100 p-1 mb-1">
                  <Divider
                    sx={{
                      "&::before, &::after": {
                        borderTop: "4px solid #d3d3d3",
                      },
                    }}
                  >
                    <p className="championText">{t("CHAMPION_TEXT")}</p>
                  </Divider>
                  <p className="subChampionText">
                    {t("CHOOSE_MORE_NUMBER_TEXT")}{" "}
                    <span className="redColorText"> 9.16</span>
                  </p>
                  {isSM ? (
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justifyCenter"
                    >
                      {BetNumbers?.map((item, index) => {
                        return (
                          <div
                            onClick={() => handleManageBetArray(item)}
                            key={index}
                          >
                            <Avatar className={handleCss(item)}>
                              <span>{item.betNumber}</span>
                            </Avatar>
                          </div>
                        );
                      })}
                    </Stack>
                  ) : (
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justifyCenter flexWrap"
                    >
                      {BetNumbers?.map((item, index) => {
                        return (
                          <div
                            onClick={() => handleManageBetArray(item)}
                            key={index}
                          >
                            <Avatar className={handleCss(item)}>
                              <span>{item.betNumber}</span>
                            </Avatar>
                          </div>
                        );
                      })}
                    </Stack>
                  )}
                  <div>
                    <p className="betText">
                      {t("SELECTED_BET_TEXT")}{" "}
                      <span style={{ fontSize: "20px", color: "#C90" }}>
                        {betArray?.length}
                      </span>{" "}
                      {t("BETS_TEXT")}
                    </p>
                  </div>
                  <div>
                    <p className="betText">{t("ODD_FOR_BET_TEXT")}</p>
                  </div>
                  <div>
                    <TextField
                      name="multiplies"
                      id="outlined-basic"
                      label="Odd"
                      variant="outlined"
                      type="number"
                      value={odds}
                      InputProps={{
                        className: classes.inputClass,
                        inputProps: { min: 1 },
                      }}
                      InputLabelProps={{
                        className: classes.inputLabelClass,
                      }}
                      onChange={(e) => handleSetOdds(e)}
                    />
                  </div>
                  <div>
                    <p className="betText">
                      {t("TOTAL_TEXT")}: {betArray?.length * odds}{" "}
                    </p>
                  </div>
                  <div className="mt-2">
                    <Stack
                      direction="row"
                      spacing={2}
                      className="justifyCenter"
                    >
                      <Button
                        size="medium"
                        className="confirmShowModalBttn"
                        onClick={() => {
                          dispatch(CheckUserAccStatus());
                          handleShowList(betArray);
                        }}
                        disabled={betArray.length > 0 ? false : true}
                      >
                        {t("CONFIRM_BUTTON_TEXT")}
                      </Button>
                      <Button
                        size="medium"
                        className="clearBttn"
                        onClick={() => {
                          dispatch(CheckUserAccStatus());
                          handleClear();
                        }}
                      >
                        {t("CLEAR_BUTTON_TEXT")}
                      </Button>
                    </Stack>
                  </div>
                  <Modal
                    open={modal}
                    // onClose={handleClose}
                  >
                    <Box sx={style}>
                      <div
                        className="textRight modalCloseIcon"
                        onClick={handleClose}
                      >
                        <Close />
                      </div>
                      <p className="championText mt-0 mb-0">
                        {t("CONFIRM_BUTTON_TEXT")}
                      </p>
                      <Backdrop
                        sx={{
                          color: "#fff",
                          zIndex: (theme) => theme.zIndex.drawer + 1,
                        }}
                        open={betSelector?.isLoadingPostUserBet}
                      >
                        <CircularProgress color="inherit" />
                      </Backdrop>
                      <Grid container>
                        <Grid item xs={4} md={4} className="borderClass">
                          <p className="lotteryProfileText">
                            {t("NUMBER_TEXT")}
                          </p>
                        </Grid>
                        <Grid item xs={4} md={4} className="borderClass">
                          <p className="lotteryProfileText">{t("ODD_TEXT")}</p>
                        </Grid>
                        <Grid item xs={4} md={4} className="borderClass">
                          <p className="lotteryProfileText">
                            {t("TOTAL_TEXT")}
                          </p>
                        </Grid>
                        {betArray?.map((item, index) => {
                          return (
                            <Fragment key={index}>
                              <Grid item xs={4} md={4} className="borderClass">
                                <p className="textCenter mt-0 mb-0">
                                  {item.betNumber}
                                </p>
                              </Grid>
                              <Grid item xs={4} md={4} className="borderClass">
                                <p className="textCenter mt-0 mb-0">
                                  {item.odds}
                                </p>
                              </Grid>
                              <Grid item xs={4} md={4} className="borderClass">
                                <p className="textCenter mt-0 mb-0">
                                  {item.multiplies}
                                </p>
                              </Grid>
                            </Fragment>
                          );
                        })}
                      </Grid>

                      {/* <TableContainer
                        component={Paper}
                        style={{ fontSize: "20px", height: "250px" }}
                      >
                        <Table sx={{ minWidth: 600 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell className="tableFont">
                                {t("NUMBER_TEXT")}
                              </TableCell>
                              <TableCell className="tableFont">
                                {t("ODD_TEXT")}
                              </TableCell>
                              <TableCell className="tableFont">
                                {t("TOTAL_TEXT")}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {betArray?.map((item, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    className="tableFont"
                                  >
                                    {item.betNumber}
                                  </TableCell>
                                  <TableCell className="tableFont">
                                    {item.odds}
                                  </TableCell>
                                  <TableCell className="tableFont">
                                    {item.multiplies}
                                    <TextField
                                        name="multiplies"
                                        id="outlined-basic"
                                        label="Odd"
                                        variant="outlined"
                                        type="number"
                                        defaultValue={modalOdds}
                                        //value={modalOdds}
                                        InputProps={{
                                          inputProps: { min: 1 },
                                        }}
                                        onChange={(e) =>
                                          handleSetModalOdds(
                                            e,
                                            item.number,
                                            index
                                          )
                                        }
                                      />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer> */}
                      <Stack
                        direction="row"
                        spacing={2}
                        className="justifyCenter mt-2"
                      >
                        <Button
                          size="medium"
                          className="confirmShowModalBttn"
                          onClick={() => handleSubmitBet(betArray)}
                          disabled={betArray.length > 0 ? false : true}
                        >
                          {t("CONFIRM_BUTTON_TEXT")}
                        </Button>
                        <Button
                          size="medium"
                          className="clearBttn"
                          onClick={() => handleClear()}
                        >
                          {t("CLEAR_BUTTON_TEXT")}
                        </Button>
                      </Stack>
                    </Box>
                  </Modal>
                </Card>
              </form>
            </Grid>
            <Grid item xs={12} md={4}>
              <Card className="lotteryCard textCenter height100">
                {betSelector?.betData?.data?.previous?.length > 0
                  ? betSelector?.betData?.data?.previous.map(
                      (item: any, index: number) => {
                        const betNumber = `${item.item1}, ${item.item2}, ${item.item3}, ${item.item4}, ${item.item5}, ${item.item6}, ${item.item7}, ${item.item8}, ${item.item9}, ${item.item10}`;
                        return (
                          <div key={index}>
                            <p className="lotteryText mt-0">
                              {item.betReference}
                            </p>
                            <p className="lotteryText">
                              <span className="redColorText">{betNumber}</span>
                            </p>
                            <Divider />
                          </div>
                        );
                      }
                    )
                  : null}
              </Card>
            </Grid>
          </Grid>
        </section>
      </Container>
    </div>
  );
};

export default Lottery;
