/* eslint-disable import/no-anonymous-default-export */
import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from "../actions/action";

const INITIAL_STATE = {
    notifications: [] as any
};

export default (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    { key: action.key, ...action.notification }
                ]
            };

        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map((notification: any) =>
                    (notification && action.dismissAll) || notification.key === action.key ? { ...notification, dismissed: true } : { ...notification }
                )
            };

        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification: any) => notification.key !== action.key
                ),
            };

        default:
            return state;
    }
};