import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./views/home";
import {
  home,
  invalid_url,
  register_page,
  login,
  lottery,
  account,
  default_page,
} from "./constant";
import Register from "./views/register";
import Login from "./views/login";
import Lottery from "./views/lottery";
import Account from "./views/account";

import { PrivateRoute } from "./privateRoute";
import { isUserExistInLocalStorage } from "./actions/user";
import { useEffect } from "react";

interface ChildProps {
  onHandleTawkMessenger: () => void;
}

const MainRoute: React.FC<ChildProps> = ({ onHandleTawkMessenger }) => {
  const localStorageUser = isUserExistInLocalStorage();
  const isAuthenticated =
    Object.keys(localStorageUser).length > 0 ? true : false;
  return (
    <Routes>
      <Route path={default_page} element={<Home />} />
      <Route path={home} element={<Home />} />
      <Route path={register_page} element={<Register />} />
      <Route path={login} element={<Login />} />
      <Route path={invalid_url} element={<Navigate to="/" />} />
      <Route
        path={lottery}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Lottery onHandleTawkMessenger={onHandleTawkMessenger} />
          </PrivateRoute>
        }
      />
      <Route
        path={account}
        element={
          <PrivateRoute isAuthenticated={isAuthenticated}>
            <Account onHandleTawkMessenger={onHandleTawkMessenger} />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default MainRoute;
