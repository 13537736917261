import {
  POST_REGISTER_REQUEST,
  POST_REGISTER_SUCCESS,
  POST_REGISTER_FAILURE,
  POST_LOGIN_REQUEST,
  POST_LOGIN_SUCCESS,
  POST_LOGIN_FAILURE,
  GET_USER_INFO_SUCCESS,
  GET_USER_INFO_FAILURE,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  GET_AGENT_REQUEST,
  GET_AGENT_SUCCESS,
  GET_AGENT_FAILURE,
} from "../actions/action";

const INITIAL_STATE = {
  isLoadingRegister: false,
  isLoadingLogin: false,
  userInfo: null,
  agentRequestData: null,
  isLoadingGetUserInfo: false,
  isLoadingUpdatePassword: false,
  isLoadingGetAgentRequest: false,
};

const UserState = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case POST_REGISTER_REQUEST:
      return {
        ...state,
        isLoadingRegister: action.payload.isLoadingRegister,
      };
    case POST_REGISTER_SUCCESS:
      return {
        ...state,
        isLoadingRegister: action.payload.isLoadingRegister,
      };
    case POST_REGISTER_FAILURE:
      return {
        ...state,
        isLoadingRegister: action.payload.isLoadingRegister,
      };
    case POST_LOGIN_REQUEST:
      return {
        ...state,
        isLoadingLogin: action.payload.isLoadingLogin,
      };
    case POST_LOGIN_SUCCESS:
      return {
        ...state,
        isLoadingLogin: action.payload.isLoadingLogin,
      };
    case POST_LOGIN_FAILURE:
      return {
        ...state,
        isLoadingLogin: action.payload.isLoadingLogin,
      };
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        isLoadingGetUserInfo: action?.payload?.isLoadingGetUserInfo,
        userInfo: action?.payload?.userInfo,
      };
    case GET_USER_INFO_FAILURE:
      return {
        ...state,
        isLoadingGetUserInfo: action?.payload?.isLoadingGetUserInfo,
      };
    case UPDATE_PASSWORD_REQUEST:
      return {
        ...state,
        isLoadingUpdatePassword: action.payload.isLoadingUpdatePassword,
      };
    case UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoadingUpdatePassword: action.payload.isLoadingUpdatePassword,
      };
    case UPDATE_PASSWORD_FAILURE:
      return {
        ...state,
        isLoadingUpdatePassword: action.payload.isLoadingUpdatePassword,
      };
    case GET_AGENT_REQUEST:
      return {
        ...state,
        isLoadingGetAgentRequest: action.payload.isLoadingGetAgentRequest,
      };
    case GET_AGENT_SUCCESS:
      return {
        ...state,
        agentRequestData: action.payload.agentRequestData,
        isLoadingGetAgentRequest: action.payload.isLoadingGetAgentRequest,
      };
    case GET_AGENT_FAILURE:
      return {
        ...state,
        isLoadingGetAgentRequest: action.payload.isLoadingGetAgentRequest,
      };
    default:
      return state;
  }
};

export default UserState;
