import { configureStore } from "@reduxjs/toolkit";
// ...
import snackbar from "./reducers/snackbar";
import bet from "./reducers/bet";
import user from "./reducers/user";
import transaction from "./reducers/transaction";
import announcement from "./reducers/announcement";
export const store = configureStore({
  reducer: {
    snackbar: snackbar,
    bet: bet,
    user: user,
    transaction: transaction,
    announcement: announcement,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
