import { useCountdown } from "../CountDown";
import Stack from "@mui/material/Stack";
import { useEffect } from "react";
import { useAppDispatch } from "../../hooks";
interface TargetDateObject {
  targetDate: string;
  betReference: string;
}

const CountDownTimer: React.FC<TargetDateObject> = ({
  targetDate,
  betReference,
}) => {
  const [minutes, seconds] = useCountdown(targetDate);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (minutes === 0 && seconds === 0) {
      window.location.reload();
    } else if (minutes < 0 && seconds < 0) {
      //Here to cater if user left the tab
      window.location.reload();
    }
  }, [minutes, seconds]);
  return (
    <Stack direction="row" spacing={2} className="justifyCenter">
      <div>
        <p className="timerText mt-0">{minutes <= 0 ? 0 : minutes}</p>
        <p className="timerText mb-0">MIN</p>
      </div>
      <div>
        <p className="timerText mt-0">:</p>
      </div>
      <div>
        <p className="timerText mt-0">{seconds <= 0 ? 0 : seconds}</p>
        <p className="timerText mb-0">SEC</p>
      </div>
    </Stack>
  );
};

export default CountDownTimer;
