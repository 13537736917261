export const home = "/home";
export const invalid_url = "/*";
export const login = "/login";
export const register_page = "/register";
export const lottery = "/lottery";
export const account = "/account";
export const default_page = "/";
//note
export const DEBIT = 1;
export const CREDIT = 2;
