import { Navigate } from "react-router-dom";
import { login } from "./constant";

interface PrivateRouteProps {
  children: any;
  isAuthenticated: boolean;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  isAuthenticated,
}) => {
  return isAuthenticated ? children : <Navigate to={login} />;
};
