import { makeStyles } from "@mui/styles";
import styles from "../../../assets/account";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAgentRequest } from "../../../actions/user";
import { isUserExistInLocalStorage } from "../../../actions/user";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import moment from "moment";

const useStyles = makeStyles(styles);

const AgentCenter = () => {
  const classes = useStyles();
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const userSelector = useAppSelector((state) => state.user);
  useEffect(() => {
    const localStorageUser = isUserExistInLocalStorage();
    if (localStorageUser?.userId) {
      dispatch(GetAgentRequest(localStorageUser?.userId));
    }
  }, [dispatch]);
  return (
    <div>
      <p className={classes.sectionTitle}>{t("AGENT_CENTER")}</p>
      <TableContainer component={Paper} style={{ maxHeight: 500 }} className={classes.tableClass}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>{t("NAME_FORM_TEXT")}</TableCell>
              <TableCell>{t("DATE_TEXT")}</TableCell>
              <TableCell>{t("TOTAL_TEXT")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userSelector?.agentRequestData?.data?.length > 0
              ? userSelector?.agentRequestData?.data?.map(
                  (item: any, index: number) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {item.userName}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss")}
                        </TableCell>

                        <TableCell>{item.total}</TableCell>
                      </TableRow>
                    );
                  }
                )
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AgentCenter;
