import {
  GET_ALL_BET_REQUEST,
  GET_ALL_BET_SUCCESS,
  GET_ALL_BET_FAILURE,
  POST_USER_BET_REQUEST,
  POST_USER_BET_SUCCESS,
  POST_USER_BET_FAILURE,
  GET_BETTING_RECORD_REQUEST,
  GET_BETTING_RECORD_SUCCESS,
  GET_BETTING_RECORD_FAILURE,
} from "./action";
import axios from "../axiosConfig";
import { ShowSnackbar } from "./snackbar";

const BET_ENDPOINT = "/api/Bet";

export const GetAllBet = () => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ALL_BET_REQUEST,
      payload: { isLoadingGetAllBet: true },
    });
    axios
      .get(BET_ENDPOINT + "/GetBetSession")
      .then((res) => {
        dispatch({
          type: GET_ALL_BET_SUCCESS,
          payload: {
            betData: res?.data,
            isLoadingGetAllBet: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ALL_BET_FAILURE,
          payload: {
            isLoadingGetAllBet: false,
            betErrorData: err?.data,
          },
        });
      });
  };
};

export const CalculateUserBet = () => {
  return (dispatch: any) => {
    axios
      .post(BET_ENDPOINT + "/Calculate", {})
      .then((res) => {})
      .catch((err) => {});
  };
};

export const CreateUserBet = (userBetRequest: any) => {
  return (dispatch: any) => {
    dispatch({
      type: POST_USER_BET_REQUEST,
      payload: { isLoadingPostUserBet: true },
    });

    axios
      .post(BET_ENDPOINT + "/UserBet", userBetRequest)
      .then((res) => {
        dispatch({
          type: POST_USER_BET_SUCCESS,
          payload: { isLoadingPostUserBet: false },
        });
        window.location.reload();
      })
      .catch((err) => {
        dispatch({
          type: POST_USER_BET_FAILURE,
          payload: { isLoadingPostUserBet: false },
        });

        dispatch(ShowSnackbar(err?.data?.Message, "error"));
      });
  };
};

export const GetBettingRecord = (userId: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_BETTING_RECORD_REQUEST,
      payload: { isLoadingGetBettingRecord: true },
    });

    axios
      .get(`${BET_ENDPOINT}/${userId}`)
      .then((res) => {
        dispatch({
          type: GET_BETTING_RECORD_SUCCESS,
          payload: {
            userBettingRecord: res?.data,
            isLoadingGetBettingRecord: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BETTING_RECORD_FAILURE,
          payload: {
            isLoadingGetBettingRecord: false,
          },
        });
      });
  };
};
