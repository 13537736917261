import {
  GET_ANNOUNCEMENT_REQUEST,
  GET_ANNOUNCEMENT_SUCCESS,
  GET_ANNOUNCEMENT_FAILURE,
} from "./action";
import axios from "../axiosConfig";

const ANNOUNCEMENT_ENDPOINT = "/api/Announcement";

export const GetAllAnnouncement = () => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ANNOUNCEMENT_REQUEST,
      payload: { isLoadingGetAnnouncement: true },
    });
    axios
      .get(ANNOUNCEMENT_ENDPOINT + "/AnnouncementList?isDelete=false")
      .then((res) => {
        dispatch({
          type: GET_ANNOUNCEMENT_SUCCESS,
          payload: {
            announcementData: res?.data,
            isLoadingGetAnnouncement: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ANNOUNCEMENT_FAILURE,
          payload: {
            isLoadingGetAnnouncement: false,
          },
        });
      });
  };
};
