import {
  GET_ALL_BET_REQUEST,
  GET_ALL_BET_SUCCESS,
  GET_ALL_BET_FAILURE,
  POST_USER_BET_REQUEST,
  POST_USER_BET_SUCCESS,
  POST_USER_BET_FAILURE,
  GET_BETTING_RECORD_REQUEST,
  GET_BETTING_RECORD_SUCCESS,
  GET_BETTING_RECORD_FAILURE,
} from "../actions/action";

const INITIAL_STATE = {
  betData: null,
  isLoadingGetAllBet: false,
  isLoadingPostUserBet: false,
  userBettingRecord: null,
  isLoadingGetBettingRecord: false,
};

const BetState = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case GET_ALL_BET_REQUEST:
      return {
        ...state,
        isLoadingGetAllBet: action.payload.isLoadingGetAllBet,
      };
    case GET_ALL_BET_SUCCESS:
      return {
        ...state,
        isLoadingGetAllBet: action.payload.isLoadingGetAllBet,
        betData: action.payload.betData,
      };
    case GET_ALL_BET_FAILURE:
      return {
        ...state,
        isLoadingGetAllBet: action.payload.isLoadingGetAllBet,
        betData: action.payload.betErrorData,
      };
    case POST_USER_BET_REQUEST:
      return {
        ...state,
        isLoadingPostUserBet: action.payload.isLoadingPostUserBet,
      };
    case POST_USER_BET_SUCCESS:
      return {
        ...state,
        isLoadingPostUserBet: action.payload.isLoadingPostUserBet,
      };
    case POST_USER_BET_FAILURE:
      return {
        ...state,
        isLoadingPostUserBet: action.payload.isLoadingPostUserBet,
      };
    case GET_BETTING_RECORD_REQUEST:
      return {
        ...state,
        isLoadingGetBettingRecord: action.payload.isLoadingGetBettingRecord,
      };
    case GET_BETTING_RECORD_SUCCESS:
      return {
        ...state,
        isLoadingGetBettingRecord: action.payload.isLoadingGetBettingRecord,
        userBettingRecord: action.payload.userBettingRecord,
      };
    case GET_BETTING_RECORD_FAILURE:
      return {
        ...state,
        isLoadingGetBettingRecord: action.payload.isLoadingGetBettingRecord,
      };
    default:
      return state;
  }
};

export default BetState;
