import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from "./action";

export const enqueueSnackbar = (notification: any) => {
  const key = notification.options && notification.options.key;
  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const ShowSnackbar = (message: string, status: string) => {
  return (dispatch: any) =>
    dispatch(
      enqueueSnackbar({
        message: message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: status,
        },
      })
    );
};

export const closeSnackbar = (key: any) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key,
  key,
});

export const removeSnackbar = (key: any) => ({
  type: REMOVE_SNACKBAR,
  key,
});
